var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center line-height-1 pb-2"},[_c('i',{staticClass:"text-primary ti ti-user-check small-gap-right"}),_c('h4',{staticClass:"text-primary mb-0"},[_vm._v("Daftar Promo")])]),_c('div',[_c('vue-good-table',{attrs:{"mode":"remote","is-loading":_vm.isLoading,"total-rows":_vm.totalRecords,"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
              enabled: true,
              initialSortBy: { field: 'name', type: 'asc' },
            },"select-options":{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: true,
              nextLabel: 'selanjutnya',
              prevLabel: 'sebelumnya',
              rowsPerPromoLabel: 'Data per halaman',
              ofLabel: 'dari',
              promoLabel: 'halaman', // for 'promos' mode
              allLabel: 'Semua',
            },"line-numbers":true},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-promo-change":_vm.onPromoChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-promo-change":_vm.onPerPromoChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'image')?_c('span',[_c('img',{attrs:{"src":props.row.image,"width":"200px"}})]):(props.column.field == 'is_visible')?_c('span',[_c('span',{staticClass:"badge",class:[props.row.is_visible ? 'badge-success' : 'badge-secondary']},[_c('i',{staticClass:"ti",class:[props.row.is_visible ? 'ti-check' : 'ti-x']})])]):(props.column.field == 'amount')?_c('span',[(props.row.type == 'Persentase')?_c('span',[_vm._v(_vm._s(props.row.amount)+"% sampai Rp."+_vm._s(props.row.max))]):(props.row.type == 'Nominal')?_c('span',[_vm._v("Rp."+_vm._s(props.row.amount))]):_vm._e()]):(props.column.field === 'action')?_c('span',{staticClass:"d-flex justify-content-start"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.handleEdit(props.row)}}},[_c('i',{staticClass:"ti ti-pencil"})]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.handleShow(props.row)}}},[_c('i',{staticClass:"ti ti-zoom-in"})]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.handleDelete(props.row)}}},[_c('i',{staticClass:"ti ti-trash"})])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('b-button',{attrs:{"variant":"primary","size":"sm","to":{
                  name: 'superadmin-promos.create',
                }}},[_c('i',{staticClass:"ti ti-plus small-gap-right"}),_vm._v(" Tambah ")])],1)])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }